import axios from "axios";
//正式ap-net
//正式yunIcu
// const header = "https://yun-new-admin-api.yunicu.com";
//测试yunIcu
// const header = "http://yun-manage-api.yunicu.cn";
//本地yunIcu
// const header = "http://192.168.31.151:10086";
// const header = "http://192.168.31.129:10086" // 王尊贞本地
const header= 'http://192.168.31.89:10086' // 韩笑本地
//视频会议结束后跳转到哪个页面
const linkHeader = 'https://www.yunicu.com/MyMeeting' //正式
// const linkHeader = 'http://192.168.31.22:8081/MyMeeting' //本机
export function get(url,params){
    return  axios.get(header+url,{
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}

export function getHeader(url,params){
    return  axios.get(url,{
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}

export function put(url,params){
    return  axios.put(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function del(url,params){
    return  axios.delete(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}

export default {
    linkHeader
}

