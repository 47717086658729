import {getHeader} from "../base";

/**
 * 时间: 2023-06-28
 * 作者: 韩广志
 * 获取我的会议列表
 */
export function getMyMeetingList (header, memberId, id) {
    return getHeader(header,{memberId, id})
}
